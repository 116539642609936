<template>
  <nav>左侧导航</nav>
</template>
<script>
export default {
  name: "leftnav"
};
</script>

<style></style>
