<template>
  <div>
    <Header />
    <div>
      <LeftNav />
      <router-view />
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import LeftNav from "@/components/LeftNav.vue";
import { test } from "@/assets/js/api";
console.log(LeftNav.name);
export default {
  name: "backstage",
  components: {
    Header,
    LeftNav
  },
  mounted() {
    // test.test().then((res) => {
    //   console.log(res);
    // });
  }
};
</script>
<style></style>
