<template>
  <header>
    头部
  </header>
</template>
<script>
export default {
  name: "Header"
};
</script>

<style></style>
